import { Link } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { Game } from '../../../interfaces/game.interface';
import GlobalButton from '../../global/global-button/global-button';
import { getLangFullPathname } from '../../global/languages/languageUtils';
import * as styles from './game-card.module.scss';
import './game-card.scss';

type RTHomeGameCard = {
  game: Game;
  gameCta: string;
  isReversed: boolean;
};

const RTHomeGameCard: FC<RTHomeGameCard> = ({ game, gameCta, isReversed }) => {
  const [currentLang, setCurrentLang] = useState('');

  const imageUrl = game.thumbnailGameImage && Array.isArray(game.thumbnailGameImage) ? game.thumbnailGameImage[0]?.url : '';

  useEffect(() => {
    const currentLang = localStorage.getItem('RT_current_lang');

    if (currentLang) {
      setCurrentLang((JSON.parse(currentLang) as { value: string }).value);
    }
  }, []);

  return (
    <div className={`flex justify-center ${isReversed ? 'flex-row-reverse' : ''}`}>
      <div className={`hidden md:flex flex-col justify-center md:w-7/12 items-start  ${isReversed ? 'pl-8 lg:pl-20' : 'pr-8 lg:pr-20'}`}>
        <h3 className={`${styles.title} title`}>
          {game.title}
          <span className={`${styles.titleBar} decorator`} style={{ backgroundColor: game.mainColor }} />
        </h3>
        <div
          className={`${styles.description} description wysiwyg`}
          dangerouslySetInnerHTML={{
            __html: game.description,
          }}
        />
        <GlobalButton animated={true} bgColor={game.mainColor} url={`games/${game.slug}`} text={gameCta} />
      </div>
      <div className={`w-full md:w-5/12`}>
        <Link to={getLangFullPathname(currentLang, `/games/${game.slug}`, true, currentLang !== '')}>
          <div className={`${styles.gameImageWrapper} game-image-wrapper aspect-w-3 aspect-h-4`}>
            <img src={imageUrl} alt='' className='object-cover' />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default RTHomeGameCard;
