import React, { FC } from 'react';
import Hero from '../../../interfaces/hero.interface';
import * as styles from './home-hero.module.scss';

const RTHomeHero: FC<Hero> = hero => {
  const imageUrl = hero && hero.heroImgRight && Array.isArray(hero.heroImgRight) ? hero.heroImgRight[0].url : '';

  const scrollDown = () => {
    document.querySelector('#games')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className={`${styles.wrapper} relative lg:flex text-white flex justify-center`}>
      <div className='container'>
        <div className='relative max-w-screen-xl mx-auto flex justify-between'>
          <div className='relative py-32 lg:py-36 xl:py-44 lg:w-8/12 xl:pl-8'>
            <div
              className={`${styles.background} bg-grey-dark absolute w-screen top-0 -left-4 md:-left-8 lg:right-0 lg:left-auto h-full bg-no-repeat bg-center bg-cover`}
              style={hero.heroImgLeft.length > 0 ? { backgroundImage: `url(${hero.heroImgLeft[0].url})` } : {}}
            />
            <div className='max-w-xl'>
              <h1 className={`${styles.title} text-3xl md:text-4xl lg:text-5xl font-black`}>
                {hero.title}
                <span className={styles.titleBar} style={{ backgroundColor: hero.homeMainColor }} />
              </h1>
              <div
                className={`${styles.description} wysiwyg`}
                dangerouslySetInnerHTML={{
                  __html: hero.description,
                }}
              />
            </div>
          </div>
          <span
            onClick={scrollDown}
            className={`${styles.scrollDown} cursor-pointer absolute transform -translate-x-1/2 left-1/2 bottom-12 md:bottom-24 flex flex-col items-center justify-center z-20`}
          >
            {hero.scrollDownText || 'Scroll'}
          </span>
          <div className='hidden lg:flex relative w-4/12 items-center justify-center -left-24'>
            <div className={`${styles.imageContainer} absolute z-10`}>
              <img src={imageUrl} alt='hero image' className='w-full h-full z-0 object-contain' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RTHomeHero;
