import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';
import { Game } from '../../../interfaces/game.interface';
import RTHomeGameCard from '../game-card/game-card';
import * as styles from './home-game.module.scss';

type RTHomeGamesProps = {
  games: Game[];
  mainColor: string;
  gameCta: string;
  title: string;
};

const RTHomeGames: FC<RTHomeGamesProps> = ({ mainColor, games, gameCta, title }) => {
  return (
    <section className='container py-8 pt-20 md:py-12 xl:py-20' id='games'>
      <div className='max-w-5xl mx-auto space-y-12'>
        <h2 className={styles.title}>
          {title || 'Games'}
          <span className={styles.titleBar} style={{ backgroundColor: mainColor }} />
        </h2>
        <div className='space-y-6 md:space-y-8'>
          {games.map((game, index) => {
            if (Object.keys(game).length !== 0) {
              return (
                <InView
                  threshold={0.4}
                  as='div'
                  key={game.id}
                  onChange={(inView, entry) => (inView ? entry.target.classList.add('is-visible') : '')}
                >
                  <RTHomeGameCard game={game} key={game.id} gameCta={gameCta} isReversed={index % 2 !== 0} />
                </InView>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default RTHomeGames;
