// extracted by mini-css-extract-plugin
export var background = "home-hero-module--background--8e3X6";
export var decoratorAnimation = "home-hero-module--decorator-animation--vsY04";
export var description = "home-hero-module--description--pjdC+";
export var imageContainer = "home-hero-module--imageContainer--2DxYo";
export var scrollDOwn = "home-hero-module--scrollDOwn--iRJmb";
export var scrollDown = "home-hero-module--scrollDown--jW-mO";
export var textAnimation = "home-hero-module--text-animation--upG3K";
export var title = "home-hero-module--title--KUAuA";
export var titleBar = "home-hero-module--titleBar--REOLg";
export var wrapper = "home-hero-module--wrapper--XC7Hp";