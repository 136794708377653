import React, { FC } from 'react';
import RTFaq from '../../components/global/faq/faq';
import RTLayout from '../../components/global/layout/layout';
import SEO from '../../components/global/seo/seo';
import RTHomeGames from '../../components/home/games/home-games';
import RTHomeHero from '../../components/home/hero/home-hero';
import Home from '../../interfaces/home.interface';

const IndexPage: FC<{ pageContext: Home }> = ({ pageContext }) => {
  const uiElements = pageContext.uiElements;

  return (
    <RTLayout
      menuText={uiElements.menuTxt[0]}
      footerText={uiElements.footerTxt[0]}
      langModalTranslations={{ title: uiElements.languageModalTitle, subtitle: uiElements.languageModalText }}
      cookie={{
        cookieContent: uiElements.cookieContent,
        cookieButtonAccept: uiElements.cookieButtonAccept,
        cookieButtonReject: uiElements.cookieButtonReject,
      }}
    >
      <div
        style={{
          backgroundImage: `url('${pageContext.homepage?.homepagePatternImage[0]?.url}')`,
          backgroundColor: pageContext?.homepage?.homepageBackgroundColor,
        }}
      >
        <SEO />
        <RTHomeHero {...pageContext.homepage} />
        <RTHomeGames
          games={pageContext.games}
          gameCta={pageContext.homepage?.gameCta}
          mainColor={pageContext.homepage?.homeMainColor}
          title={uiElements?.homepageTitleTxt[0]?.gameSectionTitle || 'Games'}
        />
        {pageContext.homepage?.questions && pageContext.homepage?.questions.length > 0 && (
          <RTFaq
            items={pageContext.homepage?.questions}
            faqImage={pageContext.homepage?.faqImage}
            faqTitle={uiElements?.homepageTitleTxt[0]?.faqSectionTitle || 'General FAQ'}
            mainColor={pageContext.homepage?.homeMainColor}
            isSkewed={true}
            textColor={pageContext.homepage?.textColor}
          />
        )}
      </div>
    </RTLayout>
  );
};

export default IndexPage;
